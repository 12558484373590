@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
    font-family: "Inter", Sans-serif;
    font-size: 15px;
}


/* Set subtitle text-stroke */
.showcase-text__item-subtitle,
.showcase-text__item:hover .showcase-text__item-subtitle{
    color: transparent;
    -webkit-text-stroke: 1px #333;
}
/* State for change to coloring text */
.showcase-text__item-subtitle.is-active{
    color: #333;
    -webkit-text-stroke: 1px transparent;
}
